@import '@/styles/artifacts.scss';































.image {
  width: 80px;
  height: 80px;
}
