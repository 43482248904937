@import '@/styles/artifacts.scss';













































.image {
  width: 80px;
  height: 80px;
}

.value {
  font-size: fontSize(fs-200);
}
