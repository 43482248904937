@import '@/styles/artifacts.scss';
























.container {
  border-radius: 15px;
  border: 2px solid colorVodafone(dark-grey);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding: 2px 4px;
}

.status {
  color: colorVodafone(dark-grey);
  font-size: 22px;
  line-height: 22px;
}

.circle {
  border-radius: 50%;
  border: 2px solid colorVodafone(dark-grey);
  margin: 0 4px;
  width: 15px;
  height: 15px;
  &.green {
    background-color: colorVodafone(green);
  }
  &.red {
    background-color: colorVodafone(vodafone-red);
  }
}

.align-right {
  margin-left: auto;
}
